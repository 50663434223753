/*
 * @Description: aiot
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: 王召强
 * @LastEditTime: 2024-08-22 15:31:40
 */
import laborRoute from './labor.js'
import { carIdentifyRoute } from './carIdentify.js'
export const protectedRoute = [
  {
    path: '/main/aiot',
    name: 'Aiot',
    component: () => import(/*/* : "Aiot页面入口" */ '$aiot/views/Index'),
    children: []
  },
  //龙门吊监测基础管理
  {
    path: '/aiot/gantryCraneBase/equipmentManagement',
    name: 'EquipmentManagement',
    component: () => import('$aiot/views/gantryCraneBase/equipmentManagement/EquipmentManagement'),
    meta: {
      name: '龙门吊设备管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_gantryCraneBase_equipmentManagement'
    }
  },
  {
    path: '/aiot/gantryCraneBase/alarmManagement',
    name: 'AlarmManagement',
    component: () => import('$aiot/views/gantryCraneBase/alarmManagement/AlarmManagement'),
    meta: {
      name: '告警管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_gantryCraneBase_alarm'
    }
  },
  {
    path: '/aiot/gantryCraneBase/hoistingCycleStatistics',
    name: 'HoistingCycleStatistics',
    component: () =>
      import('$aiot/views/gantryCraneBase/hoistingCycleStatistics/HoistingCycleStatistics'),
    meta: {
      name: '吊装循环统计',
      isAuth: true,
      authCode: 'web_base_IoTplatform_gantryCraneBase_hoistingCycle'
    }
  },
  // {
  //   path: '/aiot/gantryCraneBase/workHourStatistics',
  //   name: 'WorkHourStatistics',
  //   component: () =>
  //     import(
  //       '$aiot/views/gantryCraneBase/workHourStatistics/WorkHourStatistics'
  //     ),
  //   meta: {
  //     name: '工时统计',
  //     isAuth: true,
  //     authCode: 'web_base_IoTplatform_gantryCraneBase_workHourStatistics',
  //   },
  // },
  {
    path: '/aiot/pageContentConfig',
    name: 'AiotPageContentConfig',
    component: () =>
      import(
        /*/* : "界面管理配置" */
        '$aiot/views/pageContentConfig/Index'
      ),
    children: [
      {
        path: '/aiot/pageContentConfig/planMarker',
        name: 'MapManagement',
        component: () =>
          import(
            /*/* : "平面图管理" */
            '$aiot/views/pageContentConfig/MapManagementList'
          ),
        meta: {
          name: '平面图管理',
          isAuth: true,
          authCode: 'web_base_IoTplatform_basics_plan',
          id: '1271809059608031233'
        }
      },
      {
        path: '/aiot/pageContentConfig/addPlanMarker',
        name: 'AddPlanMarker',
        component: () =>
          import(
            /*/* : "新增平面图" */
            '$aiot/views/pageContentConfig/planMarker/Add'
          ),
        meta: {
          name: '新增',
          isAuth: false,
          authCode: 'web_base_IoTplatform_basics_plan_add',
          id: '1271809059608031233'
        }
      },
      {
        path: '/aiot/pageContentConfig/editPlanMarker',
        name: 'EditPlanMarker',
        component: () =>
          import(
            /*/* : "编辑平面图" */
            '$aiot/views/pageContentConfig/planMarker/Add'
          ),
        meta: {
          name: '编辑',
          isAuth: false,
          authCode: 'web_base_IoTplatform_basics_plan_edit',
          id: '1271808925147033601'
        }
      },
      {
        path: '/aiot/pageContentConfig/maintainMarker',
        name: 'MaintainMarker',
        component: () =>
          import(
            /*/* : "平面图标点" */
            '$aiot/views/pageContentConfig/planMarker/MaintainMarkerNew'
          ),
        meta: {
          name: '平面图标点',
          isAuth: false,
          authCode: 'web_base_IoTplatform_basics_plan_marker'
        }
      }
    ]
  },
  {
    path: '/aiot/equipmentManagement',
    name: 'AiotEquipmentManagement',
    component: () =>
      import(
        /*/* : "设备管理" */
        '$aiot/views/equipmentManagement/Index'
      ),
    children: [
      {
        path: '/aiot/equipmentManagement/deviceManagement',
        name: 'AIOTDeviceManagement',
        component: () =>
          import(
            /*/* : "设备服务中心" */
            '$aiot/views/equipmentManagement/DeviceManagement'
          ),
        meta: {
          name: '设备中心',
          isAuth: false,
          authCode: ''
        }
      },
      {
        path: '/aiot/equipmentManagement/towerCraneMonitoring',
        name: 'TowerCraneMonitoring',
        component: () =>
          import(
            /*/* : "塔机设备管理" */
            '$aiot/views/equipmentManagement/towerCrane/TowerCraneMonitoring'
          ),
        meta: {
          name: '塔机设备管理',
          isAuth: false,
          authCode: 'web_base_IoTplatform_towerBase_manage',
          id: '1271809059608031233'
        }
      },
      {
        path: '/aiot/equipmentManagement/JackingOperationApproval',
        name: 'JackingOperationApproval',
        component: () =>
          import(
            /*/* : "顶升作业审批设置" */
            '$aiot/views/equipmentManagement/towerCrane/JackingOperationApproval'
          ),
        meta: {
          name: '顶升作业审批设置',
          isAuth: true,
          authCode: 'web_base_IoTplatform_towerBase_JackingOperationApproval'
        }
      }
    ]
  },
  {
    path: '/aiot/standardCuringRoom/device/list',
    name: 'StandardCuringRoomDevice',
    component: () => import('$aiot/views/equipmentManagement/standardCuringRoom/device/DeviceList'),
    meta: {
      name: '标养室监测',
      isAuth: true,
      authCode: 'web_base_IoTplatform_standardRoom_standardRoom',
      id: '1271809059608031233'
    }
  },
  {
    path: '/aiot/standardRoom/MonitorData',
    name: 'StandardRoomMonitor',
    component: () => import('$aiot/views/standardRoom/Index'),
    meta: {
      name: '标养室监测台账',
      isAuth: true,
      keepAlive: true, //此组件需要被缓存
      authCode: 'web_base_monitoringData_standardRoomMonitor',
      id: '1271809059608031233'
    }
  },
  {
    path: '/aiot/concrete/MonitorData',
    name: 'ConcreteMonitor',
    component: () => import('$aiot/views/concrete/Index'),
    meta: {
      name: '混凝土监测台账',
      isAuth: true,
      keepAlive: true, //此组件需要被缓存
      authCode: 'web_base_monitoringData_concreteMonitor',
      id: '1271809059608031233'
    }
  },
  {
    path: '/aiot/standardRoom/MonitorData/realTime/history',
    name: 'RealTimeHistory',
    component: () => import('$aiot/views/standardRoom/History'),
    meta: {
      name: '历史数据',
      isAuth: true,
      authCode: 'web_base_monitoringData_standardRoomMonitor_realTime_history',
      id: ''
    }
  },
  // 混凝土台账
  {
    path: '/aiot/concrete/MonitorData/realTime/history',
    name: 'ConcreteHistory',
    component: () => import('$aiot/views/concrete/History'),
    meta: {
      name: '历史数据',
      isAuth: false,
      authCode: 'web_base_monitoringData_concreteMonitor_realTime_history',
      id: ''
    }
  },
  {
    path: '/aiot/standardCuringRoom/device/add',
    name: 'AddStandardCuringRoom',
    component: () => import('$aiot/views/equipmentManagement/standardCuringRoom/device/SetDevice'),
    meta: {
      name: '新增标养室',
      isAuth: false,
      authCode: 'web_base_IoTplatform_standardRoom_standardRoom_add',
      id: '1271809059608031233'
    }
  },
  {
    path: '/aiot/standardCuringRoom/device/edit',
    name: 'EidtStandardCuringRoom',
    component: () => import('$aiot/views/equipmentManagement/standardCuringRoom/device/SetDevice'),
    meta: {
      name: '编辑标养室',
      isAuth: false,
      authCode: 'web_base_IoTplatform_standardRoom_standardRoom_edit',
      id: '1271809059608031233'
    }
  },
  {
    path: '/aiot/standardCuringRoom/device/detail',
    name: 'DetailStandardCuringRoom',
    component: () => import('$aiot/views/equipmentManagement/standardCuringRoom/device/Detail'),
    meta: {
      name: '详情',
      isAuth: false,
      authCode: 'web_base_IoTplatform_standardRoom_standardRoom_detail',
      id: '1271809059608031233'
    }
  },
  {
    path: '/aiot/aiIdentify',
    name: 'AiIdentifyIndex',
    component: () =>
      import(
        /*/* : "ai入口文件" */
        '$aiot/views/aiIdentify/Index'
      ),
    children: [
      {
        path: '/aiot/aiIdentify/deviceManagement',
        name: 'AiDeviceManagement',
        component: () =>
          import(
            /*/* : "ai-设备管理" */
            '$aiot/views/aiIdentify/DeviceManagement.vue'
          ),
        meta: {
          isAuth: false,
          name: '设备管理',
          authCode: ''
        }
      },
      {
        path: '/aiot/aiIdentify/manufacturerSet',
        name: 'AiManufacturerSet',
        component: () =>
          import(
            /*/* : "AI识别-供应商管理" */
            '$aiot/views/aiIdentify/ManufacturerSet.vue'
          ),
        meta: {
          isAuth: false,
          name: '供应商管理',
          authCode: ''
        }
      },
      {
        path: '/aiot/aiIdentify/eventStatistics',
        name: 'AiEventStatistics',
        component: () =>
          import(
            /*/* : "AI识别-AI识别事件统计" */
            '$aiot/views/aiIdentify/AiEventStatistics.vue'
          ),
        meta: {
          isAuth: false,
          name: 'AI识别事件统计',
          authCode: ''
        }
      }
    ]
  },
  /*视频监控*/
  {
    path: '/iot/videoMonitoring/deviceManagement',
    name: 'VideoMonitoringEquipment',
    meta: {
      isAuth: false,
      name: '监控设备管理',
      keepAlive: true, //此组件需要被缓存
      isBack: false, //用于判断上一个页面是哪个
      authCode: ''
    },
    component: () =>
      import(
        /*/* : "视频监控-监控设备管理" */
        '$aiot/views/videoMonitoring/MonitoringEquipment.vue'
      )
  },
  {
    path: '/iot/videoMonitoring/addME',
    name: 'AddVideoME',
    meta: {
      name: '新增监控设备',
      authCode: ''
    },
    component: () =>
      import(
        /*/* : "视频监控-新增监控设备" */
        '$aiot/views/videoMonitoring/SetME.vue'
      )
  },
  {
    path: '/iot/videoMonitoring/editME',
    name: 'EditVideoME',
    meta: {
      isAuth: false,
      name: '编辑监控设备',
      authCode: ''
    },
    component: () =>
      import(
        /*/* : "视频监控-编辑监控设备" */
        '$aiot/views/videoMonitoring/SetME.vue'
      )
  },
  {
    path: '/iot/videoMonitoring/type',
    name: 'VideoMonitoringType',
    meta: {
      isAuth: true,
      name: '监控类型设置',
      authCode: ''
    },
    component: () =>
      import(
        /*/* : "视频监控-监控类型设置" */
        '$aiot/views/videoMonitoring/MonitorType.vue'
      )
  },
  {
    path: '/iot/videoMonitoring/monitoringPreview',
    name: 'VideoMonitoringPreview',
    meta: {
      name: '视频监控预览',
      keepAlive: true, //此组件需要被缓存
      authCode: ''
    },
    component: () =>
      import(
        /*/* : "视频监控-视频监控预览" */
        '$aiot/views/videoMonitoring/MonitoringPreview.vue'
      )
  },
  {
    path: '/iot/videoMonitoring/monitoringPreview/photoSnapshotDetail',
    name: 'VideoPhotoSnapshotDetail',
    meta: {
      name: '视频快照详情'
    },
    component: () =>
      import(
        /*/* : "视频监控-视频监控预览" */
        '$aiot/views/videoMonitoring/PhotoSnapshotDetail.vue'
      )
  },
  /*标版视频监控*/
  {
    path: '/iot/standard/videoMonitoring/deviceManagement',
    name: 'MonitoringEquipment',
    meta: {
      isAuth: false,
      name: '摄像机管理',
      keepAlive: true, //此组件需要被缓存
      isBack: false, //用于判断上一个页面是哪个
      authCode: 'web_base_IoTplatform_video_device'
    },
    component: () =>
      import(
        /*/* : "视频监控-监控设备管理" */
        '$aiot/views/standard/videoMonitoring/MonitoringEquipment.vue'
      )
  },
  {
    path: '/iot/standard/videoMonitoring/addME',
    name: 'addME',
    meta: {
      name: '新增监控设备',
      authCode: 'web_base_IoTplatform_video_device_addME'
    },
    component: () =>
      import(
        /*/* : "视频监控-新增监控设备" */
        '$aiot/views/standard/videoMonitoring/SetME.vue'
      )
  },
  {
    path: '/iot/standard/videoMonitoring/editME',
    name: 'editME',
    meta: {
      isAuth: false,
      name: '编辑监控设备',
      authCode: 'web_base_IoTplatform_video_device_editME'
    },
    component: () =>
      import(
        /*/* : "视频监控-编辑监控设备" */
        '$aiot/views/standard/videoMonitoring/SetME.vue'
      )
  },
  {
    path: '/iot/standard/videoMonitoring/type',
    name: 'MonitorType',
    meta: {
      isAuth: false,
      name: '监控类型设置',
      authCode: 'web_base_IoTplatform_video_type'
    },
    component: () =>
      import(
        /*/* : "视频监控-监控类型设置" */
        '$aiot/views/standard/videoMonitoring/MonitorType.vue'
      )
  },
  /********************* */
  {
    path: '/aiot/concreteTemperatureMonitoring/list',
    name: 'ConcreteTemperatureMonitoring',
    component: () =>
      import(
        '$aiot/views/equipmentManagement/concreteTemperatureMonitoring/ConcreteTemperatureMonitoring'
      ),
    meta: {
      name: '混凝土温度监测',
      isAuth: true,
      authCode: 'web_base_IoTplatform_concrete_concrete'
    }
  },
  {
    path: '/aiot/concretePositionConfig',
    name: 'ConcretePositionConfig',
    meta: {
      name: '安装位置配置',
      isAuth: true,
      authCode: 'web_base_IoTplatform_concrete_position'
    },
    component: () =>
      import('$aiot/views/equipmentManagement/positionConfig/concretePositionConfig.vue')
  },
  {
    path: '/aiot/concreteTemperatureMonitoring/add',
    name: 'AddConcreteTemperatureMonitoring',
    component: () =>
      import('$aiot/views/equipmentManagement/concreteTemperatureMonitoring/SetDevice'),
    meta: {
      name: '添加监测设备',
      isAuth: false,
      authCode: 'web_base_IoTplatform_concrete_concrete_add'
    }
  },
  {
    path: '/aiot/concreteTemperatureMonitoring/edit',
    name: 'EidtConcreteTemperatureMonitoring',
    component: () =>
      import('$aiot/views/equipmentManagement/concreteTemperatureMonitoring/SetDevice'),
    meta: {
      name: '编辑监测设备',
      isAuth: false,
      authCode: 'web_base_IoTplatform_concrete_concrete_edit'
    }
  },
  {
    path: '/aiot/concreteTemperatureMonitoring/detail',
    name: 'DetailConcreteTemperatureMonitoring',
    component: () => import('$aiot/views/equipmentManagement/concreteTemperatureMonitoring/Detail'),
    meta: {
      name: '详情',
      isAuth: false,
      authCode: 'web_base_IoTplatform_concrete_concrete_detail'
    }
  },
  {
    path: '/aiot/concreteTemperatureMonitoring/locationConfig',
    name: 'LocationConfig',
    component: () =>
      import('$aiot/views/equipmentManagement/concreteTemperatureMonitoring/LocationConfig'),
    meta: {
      name: '测位配置',
      isAuth: false,
      authCode: 'web_base_IoTplatform_concrete_concrete_config'
    }
  },
  {
    path: '/iot/dustSpray/deviceManagement',
    name: 'SprayDeviceManagement',
    component: () => import('$aiot/views/dustSpray/DeviceManagement'),
    meta: {
      name: '设备管理',
      isAuth: false,
      authCode: ''
    }
  },
  {
    path: '/iot/dustSpray/sprayRecord',
    name: 'SprayRecord',
    component: () => import('$aiot/views/dustSpray/SprayRecord'),
    meta: {
      name: '喷淋记录',
      isAuth: false,
      authCode: ''
    }
  },
  // 门禁管理模块
  // {
  //   path: '/aiot/AccessControl',
  //   name: 'supplierMaManagement',
  //   component: () => import('$aiot/views/AccessControl/SupplierManagement'),
  //   meta: {
  //     name: '供应商管理',
  //     isAuth: true,
  //     authCode: '',
  //   },
  // },
  // {
  //   path: '/aiot/AccessControl/Equipmentmanagement',
  //   name: 'Equipmentmanagement',
  //   component: () => import('$aiot/views/AccessControl/Equipmentmanagement'),
  //   meta: {
  //     name: '设备管理',
  //     isAuth: true,
  //     authCode: '',
  //   },
  // },

  //AI识别基础管理-事件视频回放设置
  {
    path: '/aiot/ai/videoPlayBackSetting',
    name: 'VideoPlayBackSetting',
    component: () => import('$aiot/views/AIManagement/AIDevice/VideoPlayBackSetting'),
    meta: {
      name: '事件视频回放设置',
      isAuth: true,
      authCode: 'web_base_IoTplatform_ai_VideoPlayBackSetting'
    }
  },
  //
  {
    path: '/aiot/ai/hazardForm',
    name: 'AIhazardForm',
    component: () => import('$aiot/views/AIManagement/AIHazardForm/RectificationNotice'),
    meta: {
      name: 'AI安全隐患表单',
      isAuth: true,
      authCode: 'web_base_IoTplatform_ai_hazardForm'
    }
  },
  // 短信推送设置
  {
    path: '/aiot/AI/shortMessage',
    name: 'shortMessage',
    component: () => import('$aiot/views/AIManagement/AIDevice/ShortMessage'),
    meta: {
      name: '短信推送设置',
      isAuth: false,
      authCode: 'web_base_IoTplatform_ai_shortMessage'
    }
  },
  //AI识别
  {
    path: '/aiot/AI/AIManager',
    name: 'AIManager',
    component: () => import('$aiot/views/AIManagement/AIDevice/AIDeviceManagement'),
    meta: {
      name: 'AI识别管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_ai_manager'
    }
  },
  //AI识别监测
  {
    path: '/aiot/AI/monitor',
    name: 'AIMonitor',
    component: () => import('$aiot/views/aiIdentify/monitor/Index'),
    meta: {
      name: 'AI识别监测',
      isAuth: true,
      authCode: 'web_base_monitoringData_aiIdentifyMonitor'
    }
  },
  {
    path: '/aiot/AI/AIManager/add',
    name: 'AIManagerAdd',
    component: () => import('$aiot/views/AIManagement/AIDevice/AddAIDevice'),
    meta: {
      name: '新增',
      isAuth: false,

      authCode: 'web_base_IoTplatform_ai_manager_add'
    }
  },
  {
    path: '/aiot/AI/AIManager/edit',
    name: 'AIManagerEdit',
    component: () => import('$aiot/views/AIManagement/AIDevice/AddAIDevice'),
    meta: {
      name: '编辑',
      isAuth: false,

      authCode: 'web_base_IoTplatform_ai_manager_edit'
    }
  },
  //安装位置管理
  {
    path: '/aiot/AI/setPositionManager',
    name: 'SetPositionManager',
    component: () => import('$aiot/views/AIManagement/AIPosition/AIPosition'),
    meta: {
      name: '安装位置管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_basics_position'
    }
  },
  //用电监测管理
  {
    path: '/aiot/electricity/electricityMonitor',
    name: 'ElectricityMonitor',
    component: () => import('$aiot/views/electricity/electricityMonitor/ElectricityMonitor'),
    meta: {
      name: '配电箱监测管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_eleMonitor_manager'
    }
  },
  //配电箱管理
  {
    path: '/aiot/electricity/electricityBox',
    name: 'ElectricityMonitor',
    component: () => import('$aiot/views/electricity/electricityBox/ElectricityBox'),
    meta: {
      name: '配电箱管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_eleMonitor_box'
    }
  },
  //配电箱台账
  {
    path: '/aiot/electricity/electricityParameter',
    name: 'ElectricityParameter',
    component: () => import('$aiot/views/electricity/electricityParameter/Index'),
    meta: {
      name: '配电箱台账',
      isAuth: true,
      authCode: 'web_base_monitoringData_distributionBoxParameter'
    }
  },
  //配电箱台账实时监测历史记录
  {
    path: '/aiot/electricity/electricityHistory',
    name: 'ElectricityHistory',
    component: () => import('$aiot/views/electricity/electricityParameter/ElectricityHistory'),
    meta: {
      name: '历史记录',
      isAuth: true,

      authCode: 'web_base_monitoringData_distributionBoxParameter_monitoring_history',
      id: ''
    }
  },
  //环境监测
  {
    path: '/aiot/environment/deviceEnvironment',
    name: 'DeviceEnvironment',
    component: () => import('$aiot/views/environmentMonitor/deviceEnvironment/DeviceEnvironment'),
    meta: {
      name: '扬尘设备设备管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_environment_device'
    }
  },
  {
    path: '/aiot/environment/add',
    name: 'AddDeviceEnvironment',
    component: () =>
      import('$aiot/views/environmentMonitor/deviceEnvironment/AddDeviceEnvironment'),
    meta: {
      name: '新增',
      isAuth: false,

      authCode: 'web_base_IoTplatform_environment_device_add'
    }
  },
  {
    path: '/aiot/environment/edit',
    name: 'EditDeviceEnvironment',
    component: () =>
      import('$aiot/views/environmentMonitor/deviceEnvironment/AddDeviceEnvironment'),
    meta: {
      name: '编辑',
      isAuth: false,

      authCode: 'web_base_IoTplatform_environment_device_edit'
    }
  },
  // 降尘喷淋设备管理 start
  {
    path: '/aiot/environment/sprayDevice',
    name: 'DeviceEnvironment',
    component: () => import('$aiot/views/environmentMonitor/sprayDevice/DeviceEnvironment'),
    meta: {
      name: '降尘喷淋设备管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_environment_sprayDevice'
    }
  },
  {
    path: '/aiot/sprayEnvironment/add',
    name: 'AddSprayDeviceEnvironment',
    component: () => import('$aiot/views/environmentMonitor/sprayDevice/AddDeviceEnvironment'),
    meta: {
      name: '新增',
      isAuth: false,

      authCode: 'web_base_IoTplatform_environment_sprayDevice_add'
    }
  },
  {
    path: '/aiot/sprayEnvironment/edit',
    name: 'EditSprayDeviceEnvironment',
    component: () => import('$aiot/views/environmentMonitor/sprayDevice/AddDeviceEnvironment'),
    meta: {
      name: '编辑',
      isAuth: false,

      authCode: 'web_base_IoTplatform_environment_sprayDevice_edit'
    }
  },
  // 降尘喷淋设备管理 end
  {
    path: '/iot/NVRAndAccount',
    name: 'NVRAndAccount',
    component: () => import('$aiot/views/NVRAndAccount/Index'),
    meta: {
      name: 'NVR与账号管理',
      isAuth: false,
      authCode: 'web_base_IoTplatform_video_/iot/NVRAndAccount'
    }
  },
  // 降尘喷淋
  {
    path: '/aiot/enviAndDust/Dust',
    name: 'enviAndDust/Dust',
    component: () => import('$aiot/views/enviAndDust/Dust'),
    meta: {
      name: '降尘喷淋',
      isAuth: false,
      authCode: ''
    }
  },
  // 环境监测
  {
    path: '/aiot/enviAndDust/Environmental',
    name: 'enviAndDust/Environmental',
    component: () => import('$aiot/views/enviAndDust/Environmental'),
    meta: {
      name: '环境监测',
      isAuth: false,
      authCode: ''
    }
  },
  // 环境监测公司
  {
    path: '/aiot/enviAndDust/EnvironmentalCompany',
    name: 'enviAndDust/EnvironmentalCompany',
    component: () => import('$aiot/views/enviAndDust/EnvironmentalCompany'),
    meta: {
      name: '环境监测',
      isAuth: false,
      authCode: ''
    }
  },
  // 用水监测台账
  {
    path: '/aiot/enviAndDust/waterMonitorAccount',
    name: 'WaterMonitorAccount',
    component: () => import('$aiot/views/waterMonitor/waterMonitorAccount/WaterMonitorAccount'),
    meta: {
      name: '智能用水监测台账',
      isAuth: true,
      authCode: 'web_base_monitoringData_intelligentWaterMonitor'
    }
  },
  // 用电监测台账
  {
    path: '/aiot/enviAndDust/electricMonitorAccount',
    name: 'ElectricMonitorAccount',
    component: () =>
      import('$aiot/views/electricity/electricMonitorAccount/ElectricMonitorAccount.vue'),
    meta: {
      name: '智能用电监测台账',
      isAuth: true,
      authCode: 'web_base_monitoringData_intelligentElectricity'
    }
  },
  //  车辆识别
  {
    path: '/aiot/carIdentify/CarIdentify',
    name: 'CarIdentify',
    component: () => import('$aiot/views/carIdentify/CarIdentify'),
    meta: {
      name: '车辆识别',
      isAuth: true,
      authCode: 'web_base_monitoringData_CarIdentify'
    }
  },
  {
    path: '/aiot/carIdentify/CarMonitorScreen',
    name: 'CarMonitorScreen',
    component: () => import('$aiot/views/carIdentify/CarMonitorScreen/'),
    meta: {
      name: '监控画面',
      isAuth: true,
      authCode: 'web_base_monitoringData_CarIdentify_detail'
    }
  },
  // 施工升降机监测
  {
    path: '/aiot/elevator',
    name: 'Elevator',
    component: () => import('$aiot/views/elevator/Index'),
    meta: {
      name: '施工升降机监测',
      isAuth: true,
      authCode: 'web_base_monitoringData_constructionElevator',
      keepAlive: true
    }
  },
  // 施工升降机监测-实时监测-历史数据
  {
    path: '/aiot/elevator/inTimeMonitor/history',
    name: 'InTimeMonitorHistory',
    component: () => import('$aiot/views/elevator/History'),
    meta: {
      name: '历史数据',
      isAuth: true,

      authCode: 'web_base_monitoringData_constructionElevator_inTimeMonitor_history'
    }
  },
  // 车辆定位监测台账
  {
    path: '/vehicleLocation',
    name: 'vehicleLocation',
    meta: {
      name: '车辆定位监测台账',
      isAuth: true,
      authCode: 'web_base_monitoringData_vehicleLoc',
      id: ''
    },
    component: () => import('$aiot/views/vehicleLocation/Index')
  },
  // 对接第三方账号管理
  {
    path: '/aiot/thirdPartyAccountManagement',
    name: 'ThirdPartyAccountManagement',
    meta: {
      name: '第三方账号管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_basics_thirdPartyAccountManagement'
    },
    component: () => import('$aiot/views/thirdParty/ThirdPartyAccountManagement')
  },
  // 气体检测
  {
    path: '/aiot/thirdPartyGas',
    name: 'ThirdPartyGas',
    meta: {
      name: '气体监测',
      isAuth: true,
      authCode: 'web_pm_IoTplatform_thirdPartyGas'
    },
    component: () => import('$aiot/views/thirdParty/ThirdPartyGas')
  },
  // 安全帽
  {
    path: '/aiot/thirdPartyHat',
    name: 'ThirdPartyHat',
    meta: {
      name: '安全帽',
      isAuth: true,
      authCode: 'web_pm_IoTplatform_thirdPartyHat'
    },
    component: () => import('$aiot/views/thirdParty/ThirdPartyHat')
  },
  // 卸料平台
  {
    path: '/aiot/thirdPartyTabDischarge',
    name: 'ThirdPartyTabDischarge',
    meta: {
      name: '卸料',
      isAuth: true,
      authCode: 'web_pm_IoTplatform_thirdPartyTabDischarge'
    },
    component: () => import('$aiot/views/thirdParty/ThirdPartyTabDischarge')
  },
  // 临边防护基础管理	- 设备管理
  {
    path: '/aiot/borderGuard/deviceManage',
    name: 'BorderGuardDeviceManage',
    component: () => import('$aiot/views/borderGuard/deviceManage/Index'),
    meta: {
      name: '临边防护基础管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_borderGuardManage_deviceManage'
    }
  },
  // 水质监测基础管理	- 设备管理
  {
    path: '/aiot/waterQuality/deviceManage',
    name: 'WaterQualityDeviceManage',
    component: () => import('$aiot/views/waterQuality/deviceManage/Index'),
    meta: {
      name: '水质监测基础管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_waterQuality_deviceManage'
    }
  },
  // 智能烟感基础管理
  {
    path: '/aiot/smokeSensing',
    name: 'SmokeSensing',
    component: () => import('$aiot/views/smokeSensing/Index'),
    meta: {
      name: '设备管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_smokeSensing_smokeSensingManage',
      keepAlive: true
    }
  },
  // 智能烟感新增
  {
    path: '/aiot/smokeSensing/Add',
    name: 'SmokeSensingAdd',
    component: () => import('$aiot/views/smokeSensing/common/AddSmokeSens'),
    meta: {
      name: '新增',
      isAuth: false,
      authCode: 'web_base_IoTplatform_smokeSensing_smokeSensingManage_add'
    }
  },
  // 智能烟感编辑
  {
    path: '/aiot/smokeSensing/Edit',
    name: 'SmokeSensingEdit',
    component: () => import('$aiot/views/smokeSensing/common/AddSmokeSens'),
    meta: {
      name: '编辑',
      isAuth: false,
      authCode: 'web_base_IoTplatform_smokeSensing_smokeSensingManage_edit'
    }
  },
  // 钢丝绳
  {
    path: '/aiot/wireRope',
    name: 'WireRope',
    meta: {
      name: '钢丝绳监测',
      isAuth: true,
      authCode: 'web_pm_IoTplatform_wireRope'
    },
    component: () => import('$aiot/views/wireRope/WireRope')
  },

  /**喷淋控制设备管理 start**/
  {
    path: '/aiot/environment/sprayControl',
    name: 'SprayControl',
    component: () => import('$aiot/views/environmentMonitor/sprayControl/SprayControl'),
    meta: {
      name: '喷淋控制设备管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_environment_sprayControl'
    }
  },
  {
    path: '/aiot/environment/sprayControl/add',
    name: 'AddSprayControl',
    component: () => import('$aiot/views/environmentMonitor/sprayControl/AddSprayControl'),
    meta: {
      name: '新增',
      isAuth: false,
      authCode: 'web_base_IoTplatform_environment_sprayControl_add'
    }
  },
  {
    path: '/aiot/environment/sprayControl/edit',
    name: 'EditSprayControl',
    component: () => import('$aiot/views/environmentMonitor/sprayControl/AddSprayControl'),
    meta: {
      name: '编辑',
      isAuth: false,
      authCode: 'web_base_IoTplatform_environment_sprayControl_edit'
    }
  },
  /**喷淋控制设备管理 end**/

  /**
   * 三方平台对接管理-青岛住建局
   */
  //  检验检测管理
  {
    path: '/thirdDocking/qingdaoBuild/inspectionManagement',
    name: 'InspectionManagement',
    component: () => import('$aiot/views/thirdDocking/qingdaoBuild/inspectionManagement/Index'),
    meta: {
      name: '检验检测管理',
      isAuth: true,
      keepAlive: true,
      doFresh: false,
      authCode: 'web_base_thirdDocking_qingdaoBuild_inspectionManagement'
    }
  },
  // 检验检测管理-取样及见证数据-新增
  {
    path: '/thirdDocking/qingdaoBuild/inspectionManagement/sampleWitnessAdd',
    name: 'SampleWitnessAdd',
    component: () =>
      import('$aiot/views/thirdDocking/qingdaoBuild/inspectionManagement/SampleWitnessAdd'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_inspectionManagement_sampleWitnessData_add'
    }
  },
  // 检验检测管理-委托及见证信息数据-新增
  {
    path: '/thirdDocking/qingdaoBuild/inspectionManagement/entrustWitnessAdd',
    name: 'EntrustWitnessAdd',
    component: () =>
      import('$aiot/views/thirdDocking/qingdaoBuild/inspectionManagement/EntrustWitnessAdd'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_inspectionManagement_entrustWitnessInfoData_add'
    }
  },
  //  渣土运输管理
  {
    path: '/thirdDocking/qingdaoBuild/muckTranManagement',
    name: 'MuckTranManagement',
    component: () => import('$aiot/views/thirdDocking/qingdaoBuild/muckTranManagement/Index'),
    meta: {
      name: '渣土运输管理',
      isAuth: true,
      keepAlive: true,
      doFresh: false,
      authCode: 'web_base_thirdDocking_qingdaoBuild_muckTranManagement'
    }
  },
  // 渣土运输管理-车辆备案信息数据-新增
  {
    path: '/thirdDocking/qingdaoBuild/muckTranManagement/vehicleRecordAdd',
    name: 'VehicleRecordAdd',
    component: () =>
      import('$aiot/views/thirdDocking/qingdaoBuild/muckTranManagement/VehicleRecordAdd'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_muckTranManagement_vehicleRecordInfo_add'
    }
  },
  // 渣土运输管理-渣土车运输管理报警信息-新增
  {
    path: '/thirdDocking/qingdaoBuild/muckTranManagement/muckTruckAlarmAdd',
    name: 'MuckTruckAlarmAdd',
    component: () =>
      import('$aiot/views/thirdDocking/qingdaoBuild/muckTranManagement/MuckTruckAlarmAdd'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_muckTranManagement_muckTruckAlarmInfo_add'
    }
  },
  //  无人机现场巡检
  {
    path: '/thirdDocking/qingdaoBuild/droneInspection',
    name: 'DroneInspection',
    component: () => import('$aiot/views/thirdDocking/qingdaoBuild/droneInspection/Index'),
    meta: {
      name: '无人机现场巡检',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_droneInspection'
    }
  },
  // 无人机现场巡检-无人机现场巡检数据-新增
  {
    path: '/thirdDocking/qingdaoBuild/droneInspection/droneInspectionAdd',
    name: 'DroneInspectionAdd',
    component: () =>
      import('$aiot/views/thirdDocking/qingdaoBuild/droneInspection/DroneInspectionAdd'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_droneInspection_droneInspectionData_add'
    }
  },

  // 倾斜摄影技术
  {
    path: '/thirdDocking/qingdaoBuild/obliquePhotography',
    name: 'ObliquePhotography',
    component: () =>
      import('$aiot/views/thirdDocking/qingdaoBuild/obliquePhotography/ObliquePhotography'),
    meta: {
      name: '倾斜摄影技术',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_obliquePhotography'
    }
  },
  // 倾斜摄影技术-新增
  {
    path: '/thirdDocking/qingdaoBuild/obliquePhotography/add',
    name: 'ObliquePhotographyAdd',
    component: () => import('$aiot/views/thirdDocking/qingdaoBuild/obliquePhotography/AppDataAdd'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_obliquePhotography_appData_add'
    }
  },
  // 防疫管理
  {
    path: '/thirdDocking/qingdaoBuild/antiepidemic',
    name: 'Antiepidemic',
    component: () => import('$aiot/views/thirdDocking/qingdaoBuild/antiepidemic/Antiepidemic'),
    meta: {
      name: '防疫管理',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_antiepidemic'
    }
  },
  // 防疫管理-新增
  {
    path: '/thirdDocking/qingdaoBuild/antiepidemic/add',
    name: 'AntiepidemicAdd',
    component: () => import('$aiot/views/thirdDocking/qingdaoBuild/antiepidemic/ManageDataAdd'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_antiepidemic_manageData_add'
    }
  },
  // 智慧展馆
  {
    path: '/thirdDocking/qingdaoBuild/wisdomHall',
    name: 'WisdomHall',
    component: () => import('$aiot/views/thirdDocking/qingdaoBuild/wisdomHall/WisdomHall'),
    meta: {
      name: '智慧展馆',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_wisdomHall'
    }
  },
  // 智慧展馆-新增
  {
    path: '/thirdDocking/qingdaoBuild/wisdomHall/add',
    name: 'WisdomHallAdd',
    component: () => import('$aiot/views/thirdDocking/qingdaoBuild/wisdomHall/HallDataAdd'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_wisdomHall_hallData_add'
    }
  },
  // 密钥设置
  {
    path: '/thirdDocking/qingdaoBuild/secretKeySetting',
    name: 'SecretKeySetting',
    component: () => import('$aiot/views/thirdDocking/qingdaoBuild/secretKeySetting/Index'),
    meta: {
      name: '密钥设置',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_secretKeySetting'
    }
  },
  // 智能安全帽
  {
    path: '/thirdDocking/qingdaoBuild/intelligentSafetyHat',
    name: 'IntelligentSafetyHat',
    component: () => import('$aiot/views/thirdDocking/qingdaoBuild/intelligentSafetyHat/Index'),
    meta: {
      name: '智能安全帽',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_intelligentSafetyHat'
    }
  },
  // 智能安全帽-新增
  {
    path: '/thirdDocking/qingdaoBuild/intelligentSafetyHatAdd',
    name: 'IntelligentSafetyHatAdd',
    component: () =>
      import('$aiot/views/thirdDocking/qingdaoBuild/intelligentSafetyHat/manageData/Add'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_intelligentSafetyHat_manageData_add'
    }
  },
  // 机器人应用
  {
    path: '/thirdDocking/qingdaoBuild/robotApplication',
    name: 'RobotApplication',
    component: () => import('$aiot/views/thirdDocking/qingdaoBuild/robotApplication/Index'),
    meta: {
      name: '机器人应用',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_robotApplication'
    }
  },
  // 机器人应用-新增
  {
    path: '/thirdDocking/qingdaoBuild/robotApplicationAdd',
    name: 'RobotApplicationAdd',
    component: () =>
      import('$aiot/views/thirdDocking/qingdaoBuild/robotApplication/manageData/Add'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_robotApplication_manageData_add'
    }
  },
  // 三维激光扫描机器人
  {
    path: '/thirdDocking/qingdaoBuild/threeDimensionalLaserScanningRobot',
    name: 'ThreeDimensionalLaserScanningRobot',
    component: () =>
      import('$aiot/views/thirdDocking/qingdaoBuild/threeDimensionalLaserScanningRobot/Index'),
    meta: {
      name: '三维激光扫描机器人',
      isAuth: true,
      authCode: 'web_base_thirdDocking_qingdaoBuild_threeDimensionalLaserScanningRobot'
    }
  },
  // 三维激光扫描机器人-新增
  {
    path: '/thirdDocking/qingdaoBuild/threeDimensionalLaserScanningRobotAdd',
    name: 'ThreeDimensionalLaserScanningRobotAdd',
    component: () =>
      import(
        '$aiot/views/thirdDocking/qingdaoBuild/threeDimensionalLaserScanningRobot/manageData/Add'
      ),
    meta: {
      name: '新增',
      isAuth: true,
      authCode:
        'web_base_thirdDocking_qingdaoBuild_threeDimensionalLaserScanningRobot_manageData_add'
    }
  },
  // BIM施工模型
  {
    path: '/thirdDocking/qingdaoBuild/bimConstructionModel',
    name: 'bimConstructionModel',
    component: () => import('$aiot/views/thirdDocking/qingdaoBuild/constructionModel/index.vue'),
    meta: {
      name: '施工模型',
      isAuth: false,
      authCode: 'web_base_thirdDocking_qingdaoBuild_bimConstructionModel_Data'
    }
  },
  // BIM施工模型 新增
  {
    path: '/thirdDocking/qingdaoBuild/bimConstructionModelAdd',
    name: 'bimConstructionModelAdd',
    component: () =>
      import('$aiot/views/thirdDocking/qingdaoBuild/constructionModel/manageData/Add'),
    meta: {
      name: '新增',
      isAuth: false,
      authCode: 'web_base_thirdDocking_qingdaoBuild_bimConstructionModel_Data_add'
    }
  },
  // BIM施工工艺模拟
  {
    path: '/thirdDocking/qingdaoBuild/bimConstructionTechnology',
    name: 'bimConstructionTechnology',
    component: () =>
      import('$aiot/views/thirdDocking/qingdaoBuild/constructionTechnology/index.vue'),
    meta: {
      name: 'BIM施工工艺模拟',
      isAuth: false,
      authCode: 'web_base_thirdDocking_qingdaoBuild_bimConstructionTechnology_Data'
    }
  },
  // BIM施工工艺模拟 新增
  {
    path: '/thirdDocking/qingdaoBuild/bimConstructionTechnologyAdd',
    name: 'bimConstructionTechnologyAdd',
    component: () =>
      import('$aiot/views/thirdDocking/qingdaoBuild/constructionTechnology/manageData/Add'),
    meta: {
      name: '新增',
      isAuth: false,
      authCode: 'web_base_thirdDocking_qingdaoBuild_bimConstructionTechnology_Data_add'
    }
  },
  //车辆清洗
  {
    path: '/aiot/washCar/index',
    name: 'AiotWashCar',
    component: () => import('$aiot/views/washCar/Index'),
    meta: {
      name: '设备管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_AiotWashCar_washCarIndex'
    }
  },
  // 混凝土拌合站-设备管理 start
  {
    path: '/aiot/mixingStationManage/deviceManage',
    name: 'MixingStationDeviceManage',
    component: () => import('$aiot/views/mixingStationManage/deviceManage/Index'),
    meta: {
      name: '设备管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_mixingStationManage_deviceManage'
    }
  },
  {
    path: '/aiot/mixingStationManage/deviceManage/add',
    name: 'MixingStationDeviceManageAdd',
    component: () => import('$aiot/views/mixingStationManage/deviceManage/Add'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_base_IoTplatform_mixingStationManage_deviceManage_add'
    }
  },
  {
    path: '/aiot/mixingStationManage/deviceManage/edit',
    name: 'MixingStationDeviceManageEdit',
    component: () => import('$aiot/views/mixingStationManage/deviceManage/Add'),
    meta: {
      name: '编辑',
      isAuth: true,
      authCode: 'web_base_IoTplatform_mixingStationManage_deviceManage_edit'
    }
  },
  {
    path: '/aiot/mixingStationManage/deviceManage/detail',
    name: 'MixingStationDeviceManageDetial',
    component: () => import('$aiot/views/mixingStationManage/deviceManage/Detail'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_base_IoTplatform_mixingStationManage_deviceManage_detail'
    }
  },
  // 混凝土拌合站-设备管理 end
  // 混凝土拌合站-生产监测 start
  {
    path: '/aiot/mixingStationManage/production',
    name: 'MixingStationProduction',
    component: () => import('$aiot/views/mixingStationManage/production/Index'),
    meta: {
      name: '生产监测',
      isAuth: true,
      authCode: 'web_base_IoTplatform_mixingStationManage_production'
    }
  },
  {
    path: '/aiot/mixingStationManage/production/detail',
    name: 'MixingStationProductionDetail',
    component: () => import('$aiot/views/mixingStationManage/production/Detail'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_base_IoTplatform_mixingStationManage_production_detail'
    }
  },
  {
    path: '/aiot/mixingStationManage/production/edit',
    name: 'MixingStationProductionEdit',
    component: () => import('$aiot/views/mixingStationManage/production/Edit'),
    meta: {
      name: '编辑',
      isAuth: true,
      authCode: 'web_base_IoTplatform_mixingStationManage_production_edit'
    }
  },
  // 混凝土拌合站-生产监测 end
  // 试验数据监测管理-设备管理 start
  {
    path: '/aiot/testDataManage/deviceManage',
    name: 'TestDataDeviceManage',
    component: () => import('$aiot/views/testDataManage/deviceManage/Index'),
    meta: {
      name: '设备管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_testDataManage_deviceManage'
    }
  },
  {
    path: '/aiot/testDataManage/deviceManage/add',
    name: 'TestDataDeviceManageAdd',
    component: () => import('$aiot/views/testDataManage/deviceManage/Add'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_base_IoTplatform_testDataManage_deviceManage_add'
    }
  },
  {
    path: '/aiot/testDataManage/deviceManage/edit',
    name: 'TestDataDeviceManageEdit',
    component: () => import('$aiot/views/testDataManage/deviceManage/Add'),
    meta: {
      name: '编辑',
      isAuth: true,
      authCode: 'web_base_IoTplatform_testDataManage_deviceManage_edit'
    }
  },
  {
    path: '/aiot/testDataManage/deviceManage/detail',
    name: 'TestDataDeviceManageDetail',
    component: () => import('$aiot/views/testDataManage/deviceManage/Detail'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_base_IoTplatform_testDataManage_deviceManage_detail'
    }
  },
  // 试验数据监测管理-设备管理 end
  // 试验数据监测管理-生产监测 start
  {
    path: '/aiot/testDataManage/production',
    name: 'TestDataManageProduction',
    component: () => import('$aiot/views/testDataManage/production/TestDataManageProduction'),
    meta: {
      name: '生产监测',
      isAuth: true,
      authCode: 'web_base_IoTplatform_testDataManage_production'
    }
  },
  {
    path: '/aiot/testDataManage/production/detail',
    name: 'TestDataManageProductionDetail',
    component: () => import('$aiot/views/testDataManage/production/Detail'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_base_IoTplatform_testDataManage_production_detail'
    }
  },
  {
    path: '/aiot/testDataManage/production/edit',
    name: 'TestDataManageProductionEdit',
    component: () => import('$aiot/views/testDataManage/production/Edit'),
    meta: {
      name: '编辑',
      isAuth: true,
      authCode: 'web_base_IoTplatform_testDataManage_production_edit'
    }
  },
  // 试验数据监测管理-生产监测 end
  // 隧道监测 start
  // 循环工序管理 start
  {
    path: '/aiot/tunnel/processManagement',
    name: 'TunnelProcessManagement',
    component: () => import('$aiot/views/tunnel/circularProcess/ProcessManagement'),
    meta: {
      name: '工序管理',
      isAuth: true,
      authCode: 'web_pm_tunnel_circularProcess_processManagement'
    }
  },
  {
    path: '/aiot/tunnel/constructionRecord',
    name: 'TunnelConstructionRecord',
    component: () => import('$aiot/views/tunnel/circularProcess/ConstructionRecord'),
    meta: {
      name: '施工记录',
      isAuth: true,
      authCode: 'web_pm_tunnel_circularProcess_constructionRecord'
    }
  },
  // 循环工序管理 end
  // 预警管理 start
  {
    path: '/aiot/tunnel/warningConfig',
    name: 'TunnelWarningConfig',
    component: () => import('$aiot/views/tunnel/warning/WarningConfig'),
    meta: {
      name: '预警配置',
      isAuth: true,
      authCode: 'web_pm_tunnel_warning_warningConfig'
    }
  },
  {
    path: '/aiot/tunnel/warningRecord',
    name: 'TunnelWarningRecord',
    component: () => import('$aiot/views/tunnel/warning/WarningRecord'),
    meta: {
      name: '预警记录',
      isAuth: true,
      authCode: 'web_pm_tunnel_warning_warningRecord'
    }
  },
  {
    path: '/aiot/tunnel/warningCauseConfig',
    name: 'TunnelWarningCauseConfig',
    component: () => import('$aiot/views/tunnel/warning/WarningCauseConfig'),
    meta: {
      name: '预警原因配置',
      isAuth: true,
      authCode: 'web_pm_tunnel_warning_warningCauseRecord'
    }
  },
  {
    path: '/aiot/tunnel/ungear',
    name: 'TunnelUngear',
    component: () => import('$aiot/views/tunnel/warning/Ungear'),
    meta: {
      name: ' 脱节原因分类配置',
      isAuth: true,
      authCode: 'web_pm_tunnel_warning_ungear'
    }
  },
  // 预警管理 end
  // 隧道信息维护 start
  {
    path: '/aiot/tunnel/rockInformation',
    name: 'TunnelRockInformation',
    component: () => import('$aiot/views/tunnel/information/RockInformation'),
    meta: {
      name: '隧道信息维护',
      isAuth: true,
      authCode: 'web_pm_tunnel_information_rockInformation'
    }
  },
  {
    path: '/aiot/tunnel/pileRecord',
    name: 'TunnelPileRecord',
    component: () => import('$aiot/views/tunnel/information/PileRecord'),
    meta: {
      name: '桩号记录',
      isAuth: true,
      authCode: 'web_pm_tunnel_circularProcess_pileRecord'
    }
  },
  // 隧道信息维护 end
  // 人员信息 start
  {
    path: '/aiot/tunnel/personnelRecord',
    name: 'TunnelPersonnelRecord',
    component: () => import('$aiot/views/tunnel/personnel/PersonnelRecord'),
    meta: {
      name: '人员进出记录',
      isAuth: true,
      authCode: 'web_pm_tunnel_personnel_personnelRecord'
    }
  },
  {
    path: '/aiot/tunnel/personnelLocation',
    name: 'TunnelPersonnelLocation',
    component: () => import('$aiot/views/tunnel/personnel/Index.vue'),
    meta: {
      name: '人员定位',
      isAuth: true,
      authCode: 'web_pm_tunnel_personnel_personnelLocation'
    }
  },
  // 人员信息 end
  // 隧道设备管理 start
  {
    path: '/aiot/tunnel/monitorManagement',
    name: 'TunnelMonitorManagement',
    component: () => import('$aiot/views/tunnel/device/MonitorManagement'),
    meta: {
      name: '监测设备',
      isAuth: false,
      authCode: 'web_pm_tunnel_device_monitorManagement'
    }
  },
  {
    path: '/aiot/tunnel/equipmentManage',
    name: 'TunnelEquipmentManage',
    component: () => import('$aiot/views/tunnel/equipmentManage/EquipmentManage'),
    meta: {
      name: '机械设备管理',
      isAuth: true,
      authCode: 'web_pm_tunnel_device_equipmentManage'
    }
  },
  // 隧道设备管理 end

  // 隧道监测 end
  ...carIdentifyRoute, //车辆定位模块路由
  ...laborRoute
]
